 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="批次编号">
				<el-input class="el_input" v-model="form.tord_batch_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.tord_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="外部单号">
				<el-input class="el_input" v-model="form.tord_origin_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="客户名称">
				<el-select class="el_input" v-model="form.customer_user_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) of customer_user_list" :key="index" :label="`${item.user_name}/${item.company_name}`" :value="item.customer_user_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="匹配词">
				<el-input class="el_input" v-model="form.tord_match_addr" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="代理名称">
				<el-input class="el_input" v-model="form.customer_agent_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="物流公司">
				<el-input class="el_input" v-model="form.logistics_company_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路名称">
				<el-input class="el_input" v-model="form.logistics_route_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未匹配" value="1"></el-option>
					<el-option label="匹配成功" value="2"></el-option>
					<el-option label="匹配失败" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加/修改/匹配时间" width="155">
					<template slot-scope="scope">
						<div class="tab_line_item">添:{{scope.row.create_time_text}}</div>
						<div class="tab_line_item">改:{{scope.row.update_time_text}}</div>
						<div class="tab_line_item">配:{{scope.row.matched_time_text}}</div>
						<div class="tab_line_item">出:{{scope.row.export_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人" width="160">
					<template slot-scope="scope">
						<div class="tab_line_item">入:{{scope.row.admin_user_info_import.tel}}/{{scope.row.admin_user_info_import.name}}</div>
						<div class="tab_line_item">出:{{scope.row.admin_user_info_export.tel}}/{{scope.row.admin_user_info_export.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="批次/导出/运单/外部编号" width="190">
					<template slot-scope="scope">
						<div class="tab_line_item">批:{{scope.row.tord_batch_num}}</div>
						<div class="tab_line_item">导:{{scope.row.tord_export_num}}</div>
						<div class="tab_line_item">运:{{scope.row.tord_num}}</div>
						<div class="tab_line_item" v-if="scope.row.tord_origin_num">外:{{scope.row.tord_origin_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户信息" width="100">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.customer_user_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.customer_user_info.user_name}}</div>
						<div class="tab_line_item">{{scope.row.customer_user_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="代理信息">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.status==2">{{scope.row.customer_agent_info.name}}/{{scope.row.customer_agent_info.city}}{{scope.row.customer_agent_info.county}}</div>
						<div class="tab_line_item" v-if="scope.row.status==2">{{scope.row.customer_agent_info.addr}}</div>
						<div class="tab_line_item">匹配词: {{scope.row.tord_match_addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="物流公司" width="80">
					<template slot-scope="scope" v-if="scope.row.status==2">
						<div class="tab_line_item">{{scope.row.logistics_company_info.name}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.logistics_company_info.link_man">{{scope.row.logistics_company_info.link_man}}</span>
							<span v-if="scope.row.logistics_company_info.link_man && scope.row.logistics_company_info.link_tel">/</span>
							<span v-if="scope.row.logistics_company_info.link_tel">{{scope.row.logistics_company_info.link_tel}}</span>
						</div>
						<div class="tab_line_item">{{scope.row.logistics_route_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="物流公司报价" width="100">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.weight_price_for_me}}元/吨</div>
						<div class="tab_line_item">{{scope.row.volume_price_for_me}}元/方</div>
						<div class="tab_line_item">{{scope.row.number_price_for_me}}元/件</div>
					</template>
				</el-table-column>
				<el-table-column label="对客户报价" width="100">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.weight_price_for_cus}}元/吨</div>
						<div class="tab_line_item">{{scope.row.volume_price_for_cus}}元/方</div>
						<div class="tab_line_item">{{scope.row.number_price_for_cus}}元/件</div>
					</template>
				</el-table-column>
				<el-table-column label="货物数量/报价" width="130">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_weight}}吨/{{scope.row.weight_freight>0?`${scope.row.weight_freight}元`:"无报价"}}</div>
						<div class="tab_line_item">{{scope.row.cargo_volume}}方/{{scope.row.volume_freight>0?`${scope.row.volume_freight}元`:"无报价"}}</div>
						<div class="tab_line_item">{{scope.row.cargo_number}}件/{{scope.row.number_freight>0?`${scope.row.number_freight}元`:"无报价"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地" width="100">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_city}}{{scope.row.case_county}}</div>
						<div class="tab_line_item">{{scope.row.case_addr}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.case_link_man">{{scope.row.case_link_man}}</span>
							<span v-if="scope.row.case_link_man && scope.row.case_link_tel">/</span>
							<span v-if="scope.row.case_link_tel">{{scope.row.case_link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地" width="180">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_city}}{{scope.row.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.aim_addr}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.aim_link_man">{{scope.row.aim_link_man}}</span>
							<span v-if="scope.row.aim_link_man && scope.row.aim_link_tel">/</span>
							<span v-if="scope.row.aim_link_tel">{{scope.row.aim_link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="状态/备注" width="80">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
						<div class="tab_line_item" v-if="scope.row.status==3">{{scope.row.match_faild_reason}}</div>
						<div class="tab_line_item" v-if="scope.row.mark">{{scope.row.mark}}</div>
					</template>
				</el-table-column>

				
				<!-- 操作行 -->
				<el-table-column label="操作" width="50">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text" style="margin:0">详情</el-button>
						<el-button v-if="scope.row.status==1" @click="match(scope.row)" size="mini" type="text" style="margin:0">匹配</el-button>
						<el-button v-if="scope.row.status==3" @click="match(scope.row)" size="mini" type="text" style="margin:0">匹配</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text" style="margin:0">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:customer_user_list="customer_user_list" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

	</div>
</template>

<script>
	import edit from './edit.vue'
	export default {
		components:{
			edit,
		},
		data() {
			return {

				//搜索条件
				form: {
					tord_batch_num:'',
					tord_num:'',
					tord_origin_num:'',
					customer_user_num:'',
					tord_match_addr:'',
					customer_agent_name:'',
					logistics_company_name:'',
					logistics_route_name:'',
					mark:'',
					status:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//基础数据
				customer_user_list:[],

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				},
				
			}
		},
		created() {

			//取出query
			for(var key in this.$route.query){
				if(this.$route.query[key]){
					this.form[key]=this.$route.query[key]
				}
			}

			//读取客户列表
			this.get_customer_user_list()
			
			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//匹配
			match(item){

				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_match_by_admin',
						tord_num:item.tord_num,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						this.get_page_data()
					},
				});
			},

			//删除用户组
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'tord',
								ctr:'tord_del_by_admin',
								tord_num:item.tord_num,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_list_by_admin',
						is_get_admin_user_info:1,
						is_get_customer_user_info:1,
						is_get_customer_agent_info:1,
						is_get_logistics_company_info:1,
						is_get_logistics_route_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//匹配完成时间
							if(item.matched_time>0){
								item.matched_time_text=this.$my.other.totime(item.matched_time);
							}else item.matched_time_text='无'

							//导出时间
							if(item.export_time>0){
								item.export_time_text=this.$my.other.totime(item.export_time);
							}else item.export_time_text='无'
							
							//状态
							switch(item.status){
								case '1':item.status_text="未匹配";break;
								case '2':item.status_text="匹配成功";break;
								case '3':item.status_text="匹配失败";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取客户列表
			get_customer_user_list(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'user_list_by_admin',
						status:1,
						num:10000
					},
					callback:(data)=>{

						this.customer_user_list=data.list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>